
/* navbar positioning */
body {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}

nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background: white;
}


.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
